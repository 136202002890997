import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/Logo.svg";

const Navbar = () => {
  const [activeLink, setActiveLink] = useState("/");
  const [isMenuOpen, setIsMenuOpen] = useState(false); // New state for mobile menu

  const url = "https://app.gplan.in/";

  // Function to set the active link and handle navigation
  const handleLinkClick = (link) => {
    if (link === "/login") {
      window.open(url, "_blank").focus();
    } else if (link === "#features") {
      setActiveLink("/#features");
    } else if (link === "quickstart") {
      window
        .open("https://calendar.app.google/Ffo5JDreFwTikriP8", "_blank")
        .focus();
    } else {
      setActiveLink(link);
    }
    setIsMenuOpen(false); // Close menu after clicking a link
  };

  return (
    <nav className="w-full border-b-2 border-blue-500 relative">
      <div className="flex justify-between items-center h-20 w-full px-4 md:px-8">
        {/* Left Side Links for Large Screens */}
        <div className="hidden lg:flex flex-grow justify-evenly">
          <ul className="flex justify-evenly items-center h-full w-full">
            <li>
              <Link
                to="/"
                onClick={() => handleLinkClick("/")}
                className={`text-lg font-semibold transition duration-300 nav-links hover:text-blue-700 ${
                  activeLink === "/"
                    ? "text-blue-800 border-b-2 border-blue-800"
                    : "text-gray-700"
                }`}
              >
                <div className="mr-2">
                  <svg
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M3 12h18M3 6h18M3 18h18" />
                  </svg>
                </div>
                Home
              </Link>
            </li>
            <li>
              <a
                href="/#features"
                onClick={() => handleLinkClick("#features")}
                className={`text-lg font-semibold transition duration-300 nav-links hover:text-blue-700 ${
                  activeLink === "/#features"
                    ? "text-blue-800 border-b-2 border-blue-800"
                    : "text-gray-700"
                }`}
              >
                Features
              </a>
            </li>
            <li>
              <Link
                to="/about"
                onClick={() => handleLinkClick("/about")}
                className={`text-lg font-semibold transition duration-300 nav-links hover:text-blue-700 ${
                  activeLink === "/about"
                    ? "text-blue-800 border-b-2 border-blue-800"
                    : "text-gray-700"
                }`}
              >
                About Us
              </Link>
            </li>
          </ul>
        </div>

        {/* Center Logo */}
        <div className="relative flex items-center justify-center h-full">
          <div
            className="h-full flex items-center justify-center px-12 relative clip-logo"
            style={{
              backgroundColor: "#1C4C82", // Background color for GPLAN logo
              clipPath: "polygon(0 0, 100% 0, 90% 100%, 10% 100%)", // Custom shape
            }}
          >
            <Logo className="h-12 w-12" />
            <span
              className="text-white font-bold text-2xl ml-2"
              style={{ fontFamily: "Barlow Semi Condensed" }}
            >
              GPLAN
            </span>
          </div>
        </div>

        {/* Right Side Links for Large Screens */}
        <div className="hidden lg:flex flex-grow justify-evenly">
          <ul className="flex justify-evenly items-center h-full w-full">
            <li>
              <a
                href="/#quickstart"
                onClick={() => handleLinkClick("quickstart")}
                className={`text-lg font-semibold transition duration-300 nav-links hover:text-blue-700 ${
                  activeLink === "quickstart"
                    ? "text-blue-800 border-b-2 border-blue-800"
                    : "text-gray-700"
                }`}
              >
                Book a Demo
              </a>
            </li>
            <li>
              <Link
                to="/pricing"
                onClick={() => handleLinkClick("/pricing")}
                className={`text-lg font-semibold transition duration-300 nav-links hover:text-blue-700 ${
                  activeLink === "/pricing"
                    ? "text-blue-800 border-b-2 border-blue-800"
                    : "text-gray-700"
                }`}
              >
                Pricing
              </Link>
            </li>
            <li>
              <a
                onClick={() => handleLinkClick("/login")}
                className={`text-lg font-semibold transition duration-300 nav-links hover:text-blue-700 ${
                  activeLink === "login"
                    ? "text-blue-800 border-b-2 border-blue-800"
                    : "text-gray-700"
                }`}
              >
                Login
              </a>
            </li>
          </ul>
        </div>

        {/* Hamburger Menu Button for Small Screens */}
        <div className="lg:hidden flex items-center">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="text-gray-700 hover:text-blue-700 focus:outline-none"
            aria-label="Toggle Menu"
          >
            {isMenuOpen ? (
              // Close Icon
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              // Hamburger Icon
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 8h16M4 16h16"
                />
              </svg>
            )}
          </button>
        </div>
      </div>

      {/* Mobile Menu Dropdown */}
      {isMenuOpen && (
        <div className="lg:hidden bg-white border-t border-blue-500">
          <ul className="flex flex-col items-center py-4 space-y-4">
            <li>
              <Link
                to="/"
                onClick={() => handleLinkClick("/")}
                className={`text-lg font-semibold transition duration-300 nav-links hover:text-blue-700 ${
                  activeLink === "/"
                    ? "text-blue-800 border-b-2 border-blue-800"
                    : "text-gray-700"
                }`}
              >
                Home
              </Link>
            </li>
            <li>
              <a
                href="/#features"
                onClick={() => handleLinkClick("#features")}
                className={`text-lg font-semibold transition duration-300 nav-links hover:text-blue-700 ${
                  activeLink === "/#features"
                    ? "text-blue-800 border-b-2 border-blue-800"
                    : "text-gray-700"
                }`}
              >
                Features
              </a>
            </li>
            <li>
              <Link
                to="/about"
                onClick={() => handleLinkClick("/about")}
                className={`text-lg font-semibold transition duration-300 nav-links hover:text-blue-700 ${
                  activeLink === "/about"
                    ? "text-blue-800 border-b-2 border-blue-800"
                    : "text-gray-700"
                }`}
              >
                About Us
              </Link>
            </li>
            <li>
              <a
                href="/#quickstart"
                onClick={() => handleLinkClick("quickstart")}
                className={`text-lg font-semibold transition duration-300 nav-links hover:text-blue-700 ${
                  activeLink === "quickstart"
                    ? "text-blue-800 border-b-2 border-blue-800"
                    : "text-gray-700"
                }`}
              >
                Book a Demo
              </a>
            </li>
            <li>
              <Link
                to="/pricing"
                onClick={() => handleLinkClick("/pricing")}
                className={`text-lg font-semibold transition duration-300 nav-links hover:text-blue-700 ${
                  activeLink === "/pricing"
                    ? "text-blue-800 border-b-2 border-blue-800"
                    : "text-gray-700"
                }`}
              >
                Pricing
              </Link>
            </li>
            <li>
              <Link
                onClick={() => handleLinkClick("/login")}
                className={`text-lg font-semibold transition duration-300 nav-links hover:text-blue-700 ${
                  activeLink === "/login"
                    ? "text-blue-800 border-b-2 border-blue-800"
                    : "text-gray-700"
                }`}
              >
                Login
              </Link>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
