import React from "react";
import PricingElement from "../components/Pricing";
import InfoSection from "../components/InfoSection";

const Pricing = () => {
  return (
    <div className="text-center">
      <PricingElement />
      <InfoSection />
    </div>
  );
};

export default Pricing;
