import React, { useState } from "react";
import axios from "axios";

const ContactUs = () => {
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");
  const [status, setStatus] = useState(null); // null, "success", or "error"

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email.trim() && text.trim()) {
      try {
        // Sending the POST request
        const response = await axios.post(
          "https://test.gplan.in/api/form/message/",
          {
            email: email.trim(),
            message: text.trim(),
          }
        );

        // Assuming a successful status code indicates success
        if (response.status === 200) {
          setStatus("success");
          console.log("Message sent successfully");
          setEmail("");
          setText("");
        } else {
          setStatus("error");
          console.error("Unexpected response:", response);
        }
      } catch (error) {
        setStatus(error?.message);
        console.log("Error sending message:", error?.message);
      }
    } else {
      setStatus("error");
    }

    // Clear status after 2 seconds
    setTimeout(() => setStatus(null), 2000);
  };

  return (
    <div className="p-6 rounded-lg shadow-lg text-left text-white font-fontp">
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="email" className="block text-white font-medium mb-2">
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300 text-black"
            placeholder="Enter your email"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="text" className="block font-medium mb-2">
            Message
          </label>
          <textarea
            id="text"
            value={text}
            onChange={(e) =>
              e.target.value.length <= 500 && setText(e.target.value)
            }
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300 text-black"
            placeholder="How can we help you (max 500 characters)"
            rows="5"
            required
          />
          <p className="text-sm">{text.length}/500 characters</p>
        </div>
        {status === "success" && (
          <p className="mt-4 text-green-500">Response saved successfully!</p>
        )}
        {status !== "error" && <p className="mt-4 text-red-500">{status}</p>}
        <button
          type="submit"
          className="text-white py-2 rounded-3xl text-lg sm:text-lg md:text-lg lg:text-xl xl:text-xl px-10 font-medium border-2 border-white md:w-2/3 hover:text-[#08203C] hover:bg-white"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ContactUs;
