// InfoSection.jsx
import React from "react";
import exploreIcon from "../assets/exploreIcon.svg"; // Replace with your imported image path
import rapidIcon from "../assets/rapidIcon.svg";
import exportIcon from "../assets/exportIcon.svg";
import { GiBullseye } from "react-icons/gi";
import { MdOutlineTimeline } from "react-icons/md";
import { FaEdit } from "react-icons/fa";

const infoArray = [
  {
    image: exploreIcon,
    title: "Scaling-Number of Rooms",
    description: "Generate floorplans with maximum 15 rooms.",
  },
  {
    image: rapidIcon,
    title: "Rapid Execution",
    description:
      "Generate dimensioned and dimensionless floorplans for given adjacency and non-adjacency in seconds.",
  },
  {
    image: exportIcon,
    title: "Export Capabilities",
    description: "Export floorplans as json, dxf and pdf.",
  },
  {
    image: <GiBullseye />,
    title: "Accuracy",
    description:
      "Generate dimensioned floorplans within given rectangular plot boundary.",
  },
  {
    image: <FaEdit />,
    title: "Editing tool",
    description:
      "Edit floorplans by moving and deleting walls and by adding doors.",
  },
  {
    image: <MdOutlineTimeline />,
    title: "Upcoming",
    description:
      "Cardinal directions, Corridors and non-rectangular boundary plots and many more.",
  },
];

const Card = ({ image, title, description }) => (
  <div className="flex flex-col items-center bg-white shadow-lg rounded-lg p-10 hover:bg-stone-200">
    {typeof image === "string" ? (
      <img src={image} alt={title} className="h-32 w-32" />
    ) : (
      <div className="h-32 w-32 flex justify-center items-center text-6xl text-[#2d52a8]">
        {image}
      </div>
    )}
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-lg text-gray-600 text-center">{description}</p>
  </div>
);

const InfoSection = () => (
  <div className="bg-gradient-to-r from-[#1C4C82] to-[#111318] pt-10 pb-24">
    <div className="container mx-auto px-4">
      <h2 className="text-left font-fontcp text-white text-7xl font-bold mb-6">
        Common Features
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 font-fontp">
        {infoArray.map((info, index) => (
          <Card
            key={index}
            image={info.image}
            title={info.title}
            description={info.description}
          />
        ))}
      </div>
    </div>
  </div>
);

export default InfoSection;
