import React, { useRef } from "react";
import Slider from "react-slick"; // Carousel library
import sampleImage from "../assets/sample_1.jpeg"; // Import the image
import sampleImage2 from "../assets/sample_2.jpeg"; // Import the image

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const OurStory = () => {
  const sliderRef = useRef(null); // Reference to the slider

  const carouselSettings = {
    dots: false, // Enable dots
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Show one full slide at a time
    slidesToScroll: 1,
    arrows: false, // Disable default arrows
    centerMode: true, // Enable centering
    centerPadding: "15%", // Show 25% of the next image
    customPaging: () => <div className="h-3 w-3 bg-white rounded-full"></div>, // Custom dots
    appendDots: (dots) => (
      <div className="flex items-center justify-center space-x-4 mt-4">
        {dots}
      </div>
    ),
    fade: false, // Add animation
    cssEase: "ease-in-out",
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          centerPadding: "5%", // Adjust center padding
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "0%", // Adjust center padding
        },
      },
    ],
  };

  return (
    <section className="bg-gradient-to-r from-[#1C4C82] to-[#111318] pt-4 pb-16 px-16 mx-5 rounded-3xl">
      {/* Section Header */}
      <div className="text-white font-fontcp mb-8 text-left ">
        <h3 className="uppercase font-semibold text-lg mb-20">
          (02) <br /> Our Story
        </h3>
        <h1 className="text-2xl sm:3xl md:text-4xl lg:text-5xl xl:text-7xl font-bold">
          2014: The Seed of an Idea
        </h1>
      </div>

      <div className="lg:flex items-start lg:space-x-8">
        {/* Left Side Text */}
        <div className="lg:w-2/5 text-white font-fontp text-lg sm:xl md:text-xl lg:text-2xl xl:text-2xl leading-relaxed text-left">
          <p>
            Prof. Shekhawat interviews with Prof. Mark Goulthorpe at MIT for a
            Postdoctoral Position, joining a project centered on the concept of
            "Design-Yourself-a-House." Although the project didn't progress due
            to funding issues, the innovative idea stayed with Prof. Shekhawat.
          </p>
          {/* Link */}
          <div className="flex items-center space-x-2 md:mt-10 lg:mt-32">
            <a
              href="#"
              className="text-white flex items-center space-x-2 font-semibold"
            >
              <span>See all</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </a>
          </div>
        </div>

        {/* Right Side Carousel */}
        <div className="w-full lg:w-3/5">
          <Slider ref={sliderRef} {...carouselSettings}>
            {/* Slide 1 */}
            <div>
              <img
                src={sampleImage}
                alt="Slide 1"
                className="w-full h-80 object-cover rounded-lg"
              />
            </div>
            {/* Slide 2 */}
            <div>
              <img
                src={sampleImage2}
                alt="Slide 2"
                className="w-full h-80 object-cover rounded-lg"
              />
            </div>
            <div>
              <img
                src={sampleImage}
                alt="Slide 3"
                className="w-full h-80 object-cover rounded-lg"
              />
            </div>
            <div>
              <img
                src={sampleImage2}
                alt="Slide 4"
                className="w-full h-80 object-cover rounded-lg"
              />
            </div>
          </Slider>

          {/* Custom Carousel Controls */}
          <div className="flex justify-start items-center mt-4 space-x-4">
            <button
              className="p-2 bg-white rounded-full shadow hover:bg-gray-300"
              onClick={() => sliderRef.current?.slickPrev()} // Use slickPrev
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-black"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
            <button
              className="p-2 bg-white rounded-full shadow hover:bg-gray-300"
              onClick={() => sliderRef.current?.slickNext()} // Use slickNext
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-black"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurStory;
