import React, { useState } from "react";
import logo from "../assets/Logo.svg"; // Import the SVG
import logo1 from "../assets/Logo-pr-1.svg";
import logo2 from "../assets/Logo-pr-2.svg";
import ContactUs from "./ContactUs";

const PricingElement = () => {
  const [isMonthly, setIsMonthly] = useState(true);
  const url = "https://app.gplan.in/";

  const getStarted = () => {
    window.open(url, "_blank").focus();
  };

  // const ContactUs = () => {
  //   window
  //     .open("https://calendar.app.google/dpTPtNSJjhhDW8qH9", "_blank")
  //     .focus();
  // };

  return (
    <div className="bg-white text-black">
      <div className="text-left">
        <div className="relative overflow-hidden">
          <img
            src={logo2}
            alt="Background SVG"
            className="absolute w-64 h-64 -bottom-32 right-16 "
          />
          <img
            src={logo1}
            alt="Background SVG"
            className="absolute hidden lg:block w-64 h-64 -bottom-28 right-[23rem]"
          />
          <img
            src={logo}
            alt="Background SVG"
            className="absolute hidden xl:block w-64 h-64 -bottom-32 right-[41rem]"
          />
          <h1
            className="text-7xl md:text-[7rem] font-fontcp -mb-4 font-bold px-10 md:px-40 bg-gradient-to-l from-[#08203c] to-[#10161e]"
            style={{
              WebkitTextStroke: "2px white", // Blue border
              border: "blue",
              textShadow: "4px 4px 6px 6px black",
              color: "transparent",
              verticalAlign: "baseline",
              lineHeight: "7rem",
            }}
          >
            Pricing
          </h1>
        </div>
        <h1 className="text-7xl md:text-[7rem] font-fontcp font-bold px-10 md:px-40 text-[#0A1F37] mb-8">
          Plans
        </h1>
        <p className="text-3xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-fontp mb-3 font-semibold px-10 md:px-40">
          Choose a plan that's right for you
        </p>
        <p className="text-lg sm:text-lg md:text-xl lg:text-2xl xl:text-2xl font-fontp text-[#3E3E3E] mb-8 px-10 md:px-40">
          Try 15 Day Trial for free
        </p>
      </div>
      {/* Toggle Button */}
      <div className="flex font-fontp justify-start mb-10 w-full sm:px-10 md:px-40">
        <button
          className={`px-9 py-3 mr-4 rounded-3xl text-lg sm:text-xl md:text-xl lg:text-2xl xl:text-2xl font-medium border-2 ${
            isMonthly ? "border-black" : "border-transparent"
          } ${isMonthly ? "bg-white text-black" : "bg-black text-white"}`}
          onClick={() => setIsMonthly(true)}
        >
          Monthly Pricing
        </button>
        {/* <button
          className={`px-9 py-3 rounded-3xl text-lg sm:text-xl md:text-xl lg:text-2xl xl:text-2xl font-medium border-2 ${
            !isMonthly ? "border-black" : ""
          } ${!isMonthly ? "bg-white text-black" : "bg-black text-white"}`}
          onClick={() => setIsMonthly(false)}
        >
          Annual Pricing
        </button> */}
      </div>

      {/* Pricing Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7 md:gap-10 lg:gap-6 xl:gap-14 px-10 md:px-10 lg:px-20 xl:px-40 text-white mb-10">
        {/* Free Trial */}
        {/* <div className="bg-[#08203C] rounded-lg px-10 py-8 text-center">
          <div className="relative text-left">
            <img
              src={logo}
              alt="Background SVG"
              className="absolute w-12 h-12 -right-3"
            />
            <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-4xl font-semibold mb-2">
              Free Trial
            </h1>
          </div>
          <p className="text-base sm:text-lg md:text-lg lg:text-xl xl:text-xl mb-8 text-left">
            Try 7 Day Trial for free.
          </p>
          <p className="text-3xl sm:text-4xl md:text-5xl lg:text-5xl xl:text-6xl font-bold mb-8 text-left">
            $0{" "}
            <span className="text-sm sm:text-base md:text-base lg:text-lg xl:text-lg">
              {" "}
              per month
            </span>
          </p>
          <button
            onClick={getStarted}
            className="text-white py-2 rounded-3xl text-lg sm:text-lg md:text-lg lg:text-xl xl:text-xl px-10 font-medium border-2 border-white md:w-2/3 hover:text-[#08203C] hover:bg-white"
          >
            Start free trial
          </button>
        </div> */}

        <div className="bg-[#08203C] rounded-lg px-10 py-8 text-center flex flex-col justify-between h-full">
          <div className="relative text-left">
            <img
              src={logo}
              alt="Background SVG"
              className="absolute w-12 h-12 -right-3"
            />
            <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-4xl font-semibold mb-2">
              Pro Plan
            </h1>
            <p className="text-base sm:text-lg md:text-lg lg:text-xl xl:text-xl mb-8 text-left">
              Perfect for personal use.
            </p>
            <p className="text-3xl sm:text-4xl md:text-5xl lg:text-5xl xl:text-6xl font-bold mb-8 text-left">
              ${isMonthly ? "5" : "50"}
              <span className="text-sm sm:text-base md:text-base lg:text-lg xl:text-lg">
                {isMonthly ? "per month" : "per year"}
              </span>
            </p>
          </div>
          <button
            onClick={getStarted}
            className="text-white py-2 rounded-3xl text-lg sm:text-lg md:text-lg lg:text-xl xl:text-xl px-10 font-medium border-2 border-white md:w-2/3 hover:text-[#08203C] hover:bg-white self-center"
          >
            Get Started
          </button>
        </div>

        <div className="bg-[#08203C] rounded-lg px-10 py-8 text-center">
          <div className="relative text-left">
            <img
              src={logo}
              alt="Background SVG"
              className="absolute w-12 h-12 -right-3"
            />
            <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-4xl font-semibold mb-2">
              Enterprise Plan
            </h1>
          </div>
          <p className="text-base sm:text-lg md:text-lg lg:text-xl xl:text-xl mb-8 text-left">
            We provide a customized solution to your business needs.
          </p>
          {/* <p className="text-3xl sm:text-4xl md:text-5xl lg:text-5xl xl:text-6xl font-bold mb-8 text-left">
            ${isMonthly ? "5" : "50"}
            <span className="text-sm sm:text-base md:text-base lg:text-lg xl:text-lg">
              {" "}
              {isMonthly ? "per month" : "per year"}
            </span>
          </p> */}
          <ContactUs />
          {/* <button
            onClick={ContactUs}
            className="text-white py-2 rounded-3xl text-lg sm:text-lg md:text-lg lg:text-xl xl:text-xl px-10 font-medium border-2 border-white md:w-2/3 hover:text-[#08203C] hover:bg-white"
          >
            Contact Us
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default PricingElement;
