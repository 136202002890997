import React from "react";
import OurMission from "../components/OurMission";
import OurStory from "../components/OurStory";
import OurFounder from "../components/OurFounder";
import OurTeam from "../components/OurTeam";

const About = () => {
  return (
    <div className="text-center">
      <OurMission />
      <OurStory />
      <OurFounder />
      <OurTeam />
    </div>
  );
};

export default About;
