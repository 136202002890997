import React from "react"
import teamPhoto from "../assets/teamPhoto.jpeg" // Replace with the actual path to the team member photo
import rahilPhoto from "../assets/profile_pic2.jpg"
import team1 from "../assets/team1.JPG"
import team2 from "../assets/team2.JPG"
import team3 from "../assets/IMG_20220901_234218_605(1).jpg"
import team4 from "../assets/Profile_RdK_300dpi.jpg"

// Dummy team data
const teamMembers = [
  {
    name: "Rahil N Jain",
    title: "Co-Founder GPLAN",
    details: [
      "Ex-Associate @Goldman Sachs",
      "Ex-Intern @Amazon",
      "MSc. Mathematics and BE. Computer Science @BITS Pilani",
    ],
    photo: rahilPhoto,
    linkedin: "https://www.linkedin.com/in/rahilnjain/", // Dummy LinkedIn link
  },
  {
    name: "Nitant Upasani",
    title: "Tech & Product Lead",
    details: [
      "Smart Architectural Technologies Group, Department of Built Environment @ Eindhoven University of Technology",
    ],
    photo: team3,
    linkedin: "https://www.linkedin.com/in/nitant-upasani-bb1a76148",
  },
  {
    name: "Shiksha",
    title: "Tech & Product Lead",
    details: [
      "Algorithmic Graph Theory Research Group @ BITS Pilani and La Trobe Australia",
    ],
    photo: team1,
    linkedin: "https://www.linkedin.com/in/shiksha-gahlawat-723050215/",
  },
  {
    name: "Rohit Lohani",
    title: "Tech & Product Lead",
    details: ["Algorithmic Graph Theory Research Group @ BITS Pilani"],
    photo: team2,
    linkedin: "https://www.linkedin.com/in/rohit-lohani-2ba030251",
  },
  {
    name: "Rui De Klerk",
    title: "External Consultant",
    details: ["Business Consultant"],
    photo: team4,
    linkedin: "https://www.linkedin.com/in/rohit-lohani-2ba030251",
  },
]

// Team Card Component
const TeamCard = ({ member }) => (
  <div className='rounded-lg overflow-hidden'>
    {/* Photo */}
    <div className='relative'>
      <img
        src={member.photo}
        alt={member.name}
        className='w-full aspect-square max-h-[550px] h-auto object-cover'
      />
      {/* Name Overlay */}
      <div className='absolute font-fontp bottom-4 text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl px-4 left-1/2 transform -translate-x-1/2 bg-transparent flex items-center justify-between border border-white rounded-full p-2'>
        <span className='text-white  font-fontbc font-semibold pr-10'>
          {member.name}
        </span>
        <a
          href={member.linkedin}
          target='_blank'
          rel='noopener noreferrer'
          className='text-white'
        >
          <div className='relative'>
            <div className='absolute top-1/2 left-full transform -translate-x-1/2 -translate-y-1/2 bg-[#0B0D17] rounded-full ml-3 p-3 -rotate-45'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-4 w-4'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M17 8l4 4m0 0l-4 4m4-4H3'
                />
              </svg>
            </div>
          </div>
        </a>
      </div>
    </div>
    {/* Details */}
    <div className='p-4 font-fontp'>
      {/* <h3 className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl">
        {member.title}
      </h3> */}
      <ul className='text-base sm:text-lg md:text-lg lg:text-xl xl:text-xl text-black mt-2 ml-2 text-left list-disc'>
        {member.details.map((detail, index) => (
          <li key={index}>{detail}</li>
        ))}
      </ul>
    </div>
  </div>
)

// OurTeam Section
const OurTeam = () => (
  <section className='bg-[#F5F8FC] py-16 px-8 lg:px-20 mb-20'>
    {/* Header Section */}
    <div className='flex flex-col lg:flex-row items-center lg:items-start justify-between mb-12 relative'>
      {/* Text Section */}
      <div className='lg:w-1/2 font-fontcp text-center lg:text-left'>
        <h3 className='uppercase  text-gray-500 text-lg font-semibold mb-2'>
          (04) <br /> Our Team
        </h3>
        <h2 className='text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-semibold'>
          Meet our Team
        </h2>
        <h3 className='text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-[#1C4C82] font-semibold mt-4'>
          Passionate. Proactive. Expert.
        </h3>
        <p className='text-gray-600 font-fontp text-sm md:text-base lg:text-lg xl:text-lg mt-4 leading-relaxed'>
          We pioneer the future of architecture and floor plan innovation.
          <br />
          Constructing the World
        </p>
      </div>
      {/* Rotated "04" */}
      <div className='absolute -top-24 right-0 transform -rotate-90 z-10'>
        <h1
          className='text-[#1c4c82] font-fontbc font-bold opacity-20 text-transparent hidden lg:block'
          style={{
            WebkitTextStroke: "5px #1c4c82", // Blue border
            fontSize: "20rem",
            border: "#1c4c82",
            textShadow: "4px 4px 6px 6px black",
            color: "white",
          }}
        >
          04
        </h1>
      </div>
    </div>

    {/* Team Members */}
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-8'>
      {teamMembers.map((member, index) => (
        <TeamCard key={index} member={member} />
      ))}
    </div>
  </section>
)

export default OurTeam
