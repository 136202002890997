import React, { useEffect, useRef } from "react";
import play from "../assets/playbutton.svg";
import { ReactComponent as BG } from "../assets/FeatureBackground.svg";

// Import videos and placeholders
import video1 from "../assets/1_short.mp4";
import video2 from "../assets/2_Short-1.mp4";
import video3 from "../assets/3_Short.mp4";
import video4 from "../assets/4-short-3.mp4";
// import placeholder1 from "../assets/short-1-placeholder.jpg";
// import placeholder2 from "../assets/short-2-placeholder.jpg";
// import placeholder3 from "../assets/short-3-placeholder.jpg";
// import placeholder4 from "../assets/short-4-placeholder.jpg";

const FeaturesSection = () => {
  return (
    <section
      id="features"
      className="relative flex flex-col items-center lg:p-12 md:p-8 p-2 bg-gradient-to-r from-[#161515] to-[#2E3138] text-white lg:w-[97%] mx-auto rounded-lg mb-10"
    >
      <div className="absolute top-0 left-0 w-full h-full">
        <BG className="absolute blur-lg hidden sm:block" />
      </div>
      <h2 className="text-3xl md:text-6xl lg:text-7xl xl:text-9xl font-fontbn mb-12">
        FEATURES
      </h2>
      <div className="relative w-full flex flex-col items-center">
        <Feature
          title="Adjacency and Non-adjacency"
          description="GPAN efficiently generates floorplans while maximally satisfying given adjacency and non-adjacency constraints."
          videoSrc={video1}
          // placeholder={placeholder1}
          isReversed={false}
          showConnector
        />
        <Feature
          title="Dimensions"
          description="For given plot size (fixed) and room sizes, GPLAN produces multiple optimum dimensioned layouts."
          videoSrc={video2}
          // placeholder={placeholder2}
          isReversed={true}
          showConnector
        />
        <Feature
          title="Number of Rooms and Layouts"
          description="GPLAN can handle any number of rooms and generates multiple layouts within a few seconds."
          videoSrc={video3}
          // placeholder={placeholder3}
          isReversed={false}
          showConnector
        />
        <Feature
          title="Floorplan Editing"
          description="Generated floorplans can be edited by moving and deleting walls, by adding doors, and can be saved as pdf or json or dxf."
          videoSrc={video4}
          // placeholder={placeholder4}
          isReversed={true}
        />
      </div>
    </section>
  );
};

const Feature = ({
  title,
  description,
  videoSrc,
  placeholder,
  isReversed,
  showConnector,
}) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && entry.intersectionRatio > 0.5) {
          const promise = videoElement.play();
          if (promise !== undefined) {
            promise
              .then(() => {})
              .catch(() => {
                videoElement.muted = true;
                videoElement.play();
              });
          }
        } else {
          videoElement.pause();
        }
      },
      { threshold: [0.5] }
    );

    if (videoElement) {
      observer.observe(videoElement);
    }

    return () => {
      if (videoElement) {
        observer.unobserve(videoElement);
      }
    };
  }, []);

  return (
    <div className="relative w-full">
      <div
        className={`flex justify-between items-center w-full lg:h-[90%] xl:h-screen ${
          isReversed ? "lg:flex-row-reverse" : "lg:flex-row"
        } flex-col`}
      >
        <div
          className={`flex items-center justify-center w-full lg:w-1/2 lg:h-5/6 border-white border-2 ${
            isReversed ? "bg-gradient-to-l" : "bg-gradient-to-r"
          } from-[#1c4c82] to-[#06101c] rounded-lg lg:rounded-full relative p-1`}
        >
          <video
            ref={videoRef}
            controls
            preload="metadata"
            poster={placeholder}
            loading="lazy"
            className="lg:w-full lg:h-full lg:rounded-full rounded-lg"
          >
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div
          className={`lg:mt-0 mt-5 text-center flex flex-col items-center w-1/2 ${
            isReversed ? "lg:ml-0 lg:mr-12" : "lg:mr-0 lg:ml-12"
          }`}
        >
          <h3 className="text-4xl md:text-6xl lg:text-7xl xl:text-9xl font-fontbn bg-gradient-to-r from-[#1c4c82] to-[#ffffff] inline-block text-transparent bg-clip-text">
            {title}
          </h3>
          <p className="text-[0.8rem] md:text-[1rem] lg:text-[1.5rem] xl:text-[2rem] font-fontp mt-4 mb-6">
            {description}
          </p>
        </div>
      </div>
      {showConnector && <ConnectorLine isReversed={isReversed} />}
    </div>
  );
};

const ConnectorLine = ({ isReversed }) => {
  return (
    <div
      className={`absolute lg:h-[380px] xl:h-[420px] lg:left-1/2 ${
        isReversed ? "rotate-45" : "-rotate-45"
      } hidden lg:block`}
      style={{
        width: "7px",
        backgroundColor: "white",
        bottom: "-220px",
      }}
    ></div>
  );
};

export default FeaturesSection;
