import React from "react";
import founderPhoto from "../assets/FOUNDER_IMAGE2.jpg"; // Replace with the correct image path
import logo from "../assets/Logo.svg"; // Replace with the correct path to the logo

const OurFounder = () => {
  // https://www.linkedin.com/in/krishnendra-shekhawat-17698b73/
  const linkdinRedirect = () => {
    window
      .open(
        "https://www.linkedin.com/in/krishnendra-shekhawat-17698b73/",
        "_blank"
      )
      .focus();
  };
  return (
    <section className="bg-[#F5F8FC] py-16 px-8 lg:px-20 relative">
      {/* Top Left Section Title */}
      <div className="top-4 left-8 lg:top-8 lg:left-16 text-left">
        <h3 className="uppercase font-fontcp text-gray-500 text-lg font-semibold">
          (03) <br /> Our Founder
        </h3>
      </div>

      <div className="flex flex-col lg:flex-row items-start lg:space-x-12 mt-8">
        {/* Left: Photo */}
        <div className="lg:w-1/3 mb-8 lg:mb-0">
          <div className="relative border-2 border-[#004AAD] rounded-lg p-2">
            <img
              src={founderPhoto}
              alt="Dr. Krishnendra Shekhawat"
              className="w-full h-auto rounded-lg"
            />
            <div
              onClick={linkdinRedirect}
              className="absolute bottom-4 left-4 bg-[#004AAD] text-white rounded-full p-3 -rotate-45 cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5 12h14m-7-7l7 7-7 7"
                />
              </svg>
            </div>
          </div>
        </div>

        {/* Right: Content */}
        <div className="lg:w-2/3 text-black">
          {/* Founder Name with Design Elements */}
          <div className="relative mt-4 mb-10">
            <h1 className="text-4xl sm:5xl md:text-5xl lg:text-6xl xl:text-7xl font-fontbn text-[#004AAD]">
              Dr. Krishnendra Shekhawat
            </h1>
            <div className="absolute -left-5 -top-[2rem] md:left-[3rem] h-8 w-8 md:h-14 md:w-14 border-t-8 border-l-8 border-[#000]"></div>
            <div className="absolute -right-5 bottom-[-2rem] md:right-[3rem] h-8 w-8 md:h-14 md:w-14 border-b-8 border-r-8 border-[#000]"></div>
          </div>

          {/* Highlighted Section with Logo Background */}
          <div className="mt-6 bg-[#004AAD] border border-[#004AAD] rounded-lg p-6 relative overflow-hidden">
            {/* Logo as Background */}
            <img
              src={logo}
              alt="Background Logo"
              className="absolute inset-0 w-full h-full object-cover opacity-10 blur-lg"
            />
            {/* Content on Top */}
            <ul className="relative font-fontbn text-white text-xl sm:2xl md:text-3xl lg:text-4xl xl:text-4xl space-y-2">
              <li>
                • Founder at <span className="">GPLAN</span>
              </li>
              <li>• Professor at BITS Pilani</li>
              <li>
                • PhD Mathematics at{" "}
                <span className="">University of Geneva</span>
              </li>
            </ul>
          </div>

          {/* Description */}
          <p className="mt-6 font-fontp text-black text-center text-base sm:text-lg md:text-lg lg:text-xl xl:text-xl leading-relaxed">
            Prof. Krishnendra Shekhawat has completed his PhD in Mathematics
            from the University of Geneva under the guidance of Prof. Daniel
            Coray. During his PhD, he collaborated with Prof. Pierre Pellegrino
            on an Architecture Design Project. After completing his PhD, he
            joined the Department of Architecture (Design & Computational Lab)
            at the University of Lisbon as a Postdoctoral Fellow under the
            guidance of Prof. Jose Pinto Duarte. Presently, he is an Associate
            Professor at the Department of Mathematics, BITS Pilani, India. Last
            year, he was appointed as a Scientific Advisor to Digital Blue Foam,
            Singapore. He has published many research papers focusing on the
            automated generation of floorplan layouts.
          </p>
        </div>
      </div>

      {/* Publications Section */}
      <div className="mt-12">
        <div className="grid grid-cols-1 font-fontp text-sm sm:text-base md:text-lg lg:text-lg xl:text-lg lg:grid-cols-2 gap-4">
          {/* Publication Cards */}
          <div className="bg-[#004AAD] text-white p-4 rounded-lg">
            <p>
              Raveena, Shekhawat, K. (2023) A Theory of L-shaped Floor-plans,
              Theoretical Computer Science, 942: 57–92.
            </p>
          </div>
          <div className="bg-white border border-black p-4 rounded-lg">
            <p>
              Shekhawat, K., Upasani, N., Bisht, S., Jain, RN. (2021) A Tool for
              Computer-Generated Dimensioned Floorplans based on given
              Adjacencies, Automation in Construction, Vol. 127.
            </p>
          </div>
          <div className="bg-white border border-black p-4 rounded-lg">
            <p>
              Bisht, S., Shekhawat, K., Upasani, N., Jain, RN., Tiwaskar, RJ.,
              Hebbar, C. (2022) Transforming an Adjacency Graph into Dimensioned
              Floorplan Layouts, Computer Graphics Forum, 1–18.
            </p>
          </div>
          <div className="bg-[#004AAD] text-white p-4 rounded-lg">
            <p>
              Shekhawat, K. (2017) A computer-generated plus-shaped arrangement
              and its architectural applications, Journal of Computational
              Design and Engineering 4(4): 318-329.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurFounder;
