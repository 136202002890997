import React from "react";
import HeroSection from "../components/Hero";
import FeaturesSection from "../components/Features";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <div className="text-center">
      <HeroSection />

      {/* Features Section */}
      <FeaturesSection />

      {/* Quickstart Section */}
      {/* <div id="quickstart" className="my-8">
        <h2 className="text-3xl font-bold">Quickstart Guide</h2>
        <p>Follow the steps to get started quickly...</p>
      </div> */}
    </div>
  );
};

export default Home;
