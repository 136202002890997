import React, { useEffect, useRef } from "react";
import { ReactComponent as LogoBlue } from "../assets/Logo-Blue.svg";
import { ReactComponent as Logo } from "../assets/Logo.svg";
import { ReactComponent as Arrow } from "../assets/arrow.svg";
import { ReactComponent as Dot } from "../assets/Dot.svg";
import { ReactComponent as Dot1 } from "../assets/Dot1.svg";
import { ReactComponent as Outer } from "../assets/outer.svg";
import { ReactComponent as Inner } from "../assets/inner.svg";

import sampleVideo from "../assets/Video-8AllFeatures.mp4";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const videoRef = useRef(null); // Reference to the video element
  const navigate = useNavigate();
  const handleClickTrial = () => {
    navigate("/pricing");
  };
  const handleClickDemo = () => {
    window
      .open("https://calendar.app.google/Ffo5JDreFwTikriP8", "_blank")
      .focus();
  };
  useEffect(() => {
    const videoElement = videoRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && entry.intersectionRatio > 0.5) {
          const promise = videoElement.play();
          if (promise !== undefined) {
            promise
              .then(() => {
                // Autoplay started
              })
              .catch((error) => {
                // Autoplay was prevented.
                videoElement.muted = true;
                videoElement.play();
              });
          }
        } else {
          videoElement.pause();
        }
      },
      {
        threshold: [0.5], // Trigger when 50% is visible
      }
    );

    if (videoElement) {
      observer.observe(videoElement);
    }

    return () => {
      if (videoElement) {
        observer.unobserve(videoElement);
      }
    };
  }, []);
  return (
    <section className="relative w-full h-auto lg:h-screen flex row-auto flex-nowrap items-center justify-evenly bg-white">
      {/* Background Logos */}
      <div className="absolute top-10 left-0 w-full h-full overflow-hidden ">
        <div className="absolute top-80 left-0 transform -translate-x-1/2 -translate-y-1/2">
          <LogoBlue className="w-[400px] h-[400px] md:w-[600px] md:h-[600px] blur-lg rotate-45" />
        </div>
        <div className="absolute bottom-1/2 right-1/2 transform translate-x-1/2 translate-y-1/2 opacity-5 hidden lg:block">
          <LogoBlue className="w-[600px] h-[600px]" />
        </div>
      </div>

      {/* Left Content */}
      <div className="flex flex-col h-full py-14 space-y-4 max-w-lg text-black">
        <h1 className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold leading-tight text-left">
          A Generative
          <br />
          <span className="text-blue-700 text-5xl md:text-6xl lg:text-7xl xl:text-8xl ">
            FLOORPLAN
          </span>
          <br />
          Designer
        </h1>
        <div className="absolute lg:static lg:flex flex-col space-y-5 bottom-24 md:left-8 left-0 ">
          <Arrow className="lg:hidden w-12 h-16 sm:w-14 sm:h-18 lg:w-16 lg:h-24" />
          <Dot className="hidden lg:hidden w-8 h-3 sm:w-10 sm:h-4 lg:w-10 lg:h-4 ml-3" />
          <Dot1 className="hidden lg:hidden w-8 h-3 sm:w-10 sm:h-4 lg:w-10 lg:h-4 ml-3" />
          <Dot1 className="hidden lg:hidden w-8 h-3 sm:w-10 sm:h-4 lg:w-10 lg:h-4 ml-3" />
          <div className="hidden lg:flex flex-col text-left text-xl sm:text-2xl lg:text-3xl">
            <span className="font-medium text-gray-700">
              REIMAGINING THE FUTURE OF
            </span>
            <span className="font-bold text-black">
              INITIAL FLOOR-PLAN DESIGN
            </span>
          </div>
        </div>
        <div className="lg:hidden flex flex-col text-left text-xl sm:text-2xl lg:text-3xl">
          <span className="font-medium text-gray-700">
            REIMAGINING THE FUTURE OF
          </span>
          <span className="font-bold text-black">
            INITIAL FLOOR-PLAN DESIGN
          </span>
        </div>
        <button
          onClick={handleClickTrial}
          className="mt-6 z-50 bg-gray-800 text-white py-4 px-6 rounded-md flex items-center justify-between hover:bg-gray-700 transition duration-300 w-full sm:w-[350px] lg:w-[400px] self-center cursor-pointer"
        >
          <span className="text-center w-full text-xl sm:text-2xl lg:text-2xl">
            GET YOUR <br /> FREE TRIAL
          </span>
          <div className="flex items-center pl-4 border-l border-gray-500">
            <Inner className="w-4 h-4 text-blue-500 ml-2" />
            <Outer className="w-6 h-6 text-blue-500 -ml-2" />
          </div>
        </button>
        <button
          onClick={handleClickDemo}
          className="mt-6 z-50 bg-blue-700 text-white py-4 px-6 rounded-md flex items-center justify-between hover:bg-blue-500 transition duration-300 w-full sm:w-[350px] lg:w-[400px] self-center cursor-pointer"
        >
          <span className="text-center w-full text-xl sm:text-2xl lg:text-2xl">
            GET YOUR <br /> DEMO
          </span>
          <div className="flex items-center pl-4 border-l border-white">
            <Inner className="w-4 h-4 text-white ml-2" />
            <Outer className="w-6 h-6 text-white -ml-2" />
          </div>
        </button>
      </div>

      {/* Right Video Player - Hidden on Small Screens */}
      <div className="hidden lg:flex items-center justify-center w-2/4 h-3/4 bg-black rounded-3xl relative overflow-hidden -mr-20">
        <div className="absolute -inset-y-16 -inset-x-48">
          <svg
            className="w-full h-full blur-sm"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
          >
            {/* Diagonal Lines from Corners */}
            <line
              x1="0"
              y1="0"
              x2="100"
              y2="100"
              stroke="white"
              strokeWidth="0.5"
            />
            <line
              x1="100"
              y1="0"
              x2="0"
              y2="100"
              stroke="white"
              strokeWidth="0.5"
            />
            <line
              x1="25"
              y1="0"
              x2="75"
              y2="100"
              stroke="white"
              strokeWidth="0.5"
            />
            <line
              x1="75"
              y1="0"
              x2="25"
              y2="100"
              stroke="white"
              strokeWidth="0.5"
            />
            {/* Horizontal Lines */}
            <line
              x1="0"
              y1="16.67"
              x2="100"
              y2="16.67"
              stroke="white"
              strokeWidth="0.5"
            />
            <line
              x1="0"
              y1="33.33"
              x2="100"
              y2="33.33"
              stroke="white"
              strokeWidth="0.5"
            />
            {/* <line
              x1="0"
              y1="50"
              x2="100"
              y2="50"
              stroke="white"
              strokeWidth="0.5"
            /> */}
            <line
              x1="0"
              y1="66.67"
              x2="100"
              y2="66.67"
              stroke="white"
              strokeWidth="0.5"
            />
            <line
              x1="0"
              y1="83.33"
              x2="100"
              y2="83.33"
              stroke="white"
              strokeWidth="0.5"
            />
            <line
              x1="0"
              y1="10"
              x2="100"
              y2="10"
              stroke="white"
              strokeWidth="0.5"
            />
            <line
              x1="0"
              y1="90"
              x2="100"
              y2="90"
              stroke="white"
              strokeWidth="0.5"
            />
          </svg>
        </div>
        <video
          ref={videoRef}
          controls
          className="relative w-[85%] h-[85%] z-10 rounded-md bg-black"
        >
          <source src={sampleVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </section>
  );
};

export default HeroSection;
