import React from "react";
import ExampleSVG from "../assets/bluepolygon.svg"; // Import the SVG

const OurMission = () => {
  const learnMoreHandle = () => {
    window
      .open(
        "https://drive.google.com/file/d/17w5pTQTxQfCngV4aQOsjUZ55bbYw8Ul-/view",
        "_blank"
      )
      .focus();
  };

  return (
    <section
      className="bg-white py-16 px-8 relative"
      style={{ font: "Poppins" }}
    >
      {/* SVG Background in Top-Left */}
      <div className="absolute top-0 left-0 h-52 md:h-72 lg:h-80 xl:h-96 z-0">
        <img
          src={ExampleSVG}
          alt="Background SVG"
          className="w-full h-full object-cover"
        />
      </div>

      {/* "01" Rotated and Positioned */}
      <div className="absolute top-0 right-36 transform -rotate-90 z-10">
        <h1
          className="text-[#1c4c82] font-fontbc font-bold opacity-20 text-transparent hidden lg:block"
          style={{
            WebkitTextStroke: "5px #1c4c82", // Blue border
            fontSize: "20rem",
            border: "#1c4c82",
            textShadow: "4px 4px 6px 6px black",
            color: "white",
          }}
        >
          01
        </h1>
      </div>

      {/* "01" Transparent Text with Border and Shadow */}
      {/* <div className="absolute top-8 right-8 transform -rotate-90 z-10">
        <h1
          className="text-transparent border-blue-600 border-2 rounded-md text-9xl font-bold opacity-100"
          style={{
            WebkitTextStroke: "2px #1E3A8A", // Blue border
            textShadow: "4px 4px 6px rgba(0, 0, 0, 0.3)", // Shadow effect
            color: "transparent", // Transparent text
          }}
        >
          01
        </h1>
      </div> */}

      {/* "Our Mission" Positioned at Top Left */}
      <div className="absolute top-10 md:top-14 left-0 md:left-6 z-10 text-left">
        <div className="flex items-center space-x-2 pl-14">
          <h2
            className="text-[#1c4c82] font-fontbc font-bold text-4xl md:text-6xl lg:text-7xl xl:text-8xl uppercase"
            style={{ WebkitTextStroke: "2px white", border: "white" }}
          >
            Our
            <br />
            Mission
          </h2>
        </div>
      </div>

      {/* Centered Text Section */}
      <div className="mt-24 md:mt-0 lg:mx-auto ml-auto w-full md:w-1/2 lg:w-2/5 font-fontp text-left z-10 flex lg:justify-center justify-end">
        <p className="text-gray-700 text-lg md:text-xl lg:text-xl xl:text-2xl space-x-7 leading-relaxed self-center">
          Our mission is to elevate the landscape of architectural design by
          pushing the boundaries of innovation, creativity, and sustainability.
          We aim to create spaces that not only inspire but also seamlessly
          blend form and function, enhancing the lives of those who inhabit
          them. Through a commitment to excellence, collaboration, and
          forward-thinking approaches, we strive to set new standards in the
          industry,
          <span className="font-bold text-blue-600">
            transforming environments and shaping the future of architecture.
          </span>
        </p>
      </div>

      {/* About Us Section */}
      <div className="mt-32 lg:flex lg:space-x-16 z-10">
        {/* Text Section */}
        <div className="lg:w-1/2 text-left">
          <h3 className="text-gray-500 font-fontcp font-semibold text-xl md:text-xl lg:text-2xl xl:text-2xl uppercase mb-14">
            (01) <br />
            About Us
          </h3>
          <p className="text-gray-700 font-fontp pr-10 text-lg leading-relaxed">
            By leveraging advanced algorithms deeply rooted in Graph Theory, we
            want to unleash architectural creativity through automated floor
            planning. Tailored for architects, designers, builders, contractors,
            and developers, GPLAN optimizes efficiency by reducing the time and
            resources typically expended in manual design processes. We aim to
            drive automation and spark innovation in floor planning for AEC
            professionals, transforming the way architectural projects are
            envisioned.
          </p>
        </div>

        {/* Call-to-Action Section */}
        <div className="lg:w-1/2 mt-8 lg:mt-0">
          <h2 className="text-gray-900 font-fontbssd text-4xl md:text-6xl lg:text-6xl xl:text-7xl font-bold leading-snug text-left">
            A <span className="text-[#1C4C82]">Game-Changing</span> Approach
            <br /> To Revolutionize <br />
            <span className="text-[#1C4C82]">Architectural</span> Design
          </h2>
          <button
            onClick={learnMoreHandle}
            className="mt-8 flex items-center space-x-2 bg-blue-600 text-white font-semibold py-3 px-6 rounded-lg shadow-lg hover:bg-blue-700 transition"
          >
            <span>Learn More</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 -rotate-45"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
          </button>
        </div>
      </div>
    </section>
  );
};

export default OurMission;
