import React, { useState } from "react";
import { ReactComponent as Logo } from "../assets/Logo.svg";
import { BsArrowBarRight, BsLinkedin } from "react-icons/bs";
import {
  FaYoutube,
  FaWhatsapp,
  FaLongArrowAltRight,
  FaCheck,
} from "react-icons/fa";
import { FiMail, FiPhone } from "react-icons/fi";
import { MdLocationOn } from "react-icons/md";
import { BiArrowFromLeft } from "react-icons/bi";
import axios from "axios";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");

  // const [showEmailPopup, setShowEmailPopup] = useState(false);

  const handleEmail = async (e) => {
    e.preventDefault();
    if (email.trim()) {
      try {
        // Sending the POST request
        const response = await axios.post("https://test.gplan.in/api/welcome", {
          email: email.trim(),
        });

        // Assuming a successful status code indicates success
        if (response.status === 200) {
          setStatus("success");
          console.log("Message sent successfully");
          setEmail("");
        } else {
          setStatus("error");
          console.error("Unexpected response:", response);
        }
      } catch (error) {
        setStatus(error?.message);
        console.log("Error sending message:", error?.message);
      }
    } else {
      setStatus("error");
    }

    // Clear status after 2 seconds
    setTimeout(() => setStatus(null), 2000);
  };

  return (
    <footer className="w-full pt-12 px-12 bg-gradient-to-r to-blue-900 from-black text-white flex flex-col items-center relative overflow-hidden">
      <div className="absolute top-36 -left-10 opacity-100">
        <Logo className="h-full w-96 blur-lg top-36 right-20" />
      </div>
      <div className="absolute top-64 right-0 rotate-45 blur-lg">
        <Logo className="h-64 w-64" />
      </div>
      <div className="flex flex-col items-center mb-6">
        <div className="text-lg font-semibold mb-4">
          Join us to get our new Update
        </div>
        <div className="relative flex items-center space-x-4">
          <input
            id="email"
            type="email"
            placeholder="Enter Your Email"
            className="p-2 rounded-md text-black w-80"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            onClick={handleEmail}
            className={`absolute bg-[#1C4C82] text-white px-4 py-2 rounded-md hover:bg-blue-600 transition right-2`}
          >
            <FaLongArrowAltRight />
          </button>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <Logo className="h-24 w-24 mb-4" />
        <h3 className="text-4xl font-bold mb-4">CREATE SOMETHING NEW TODAY!</h3>
        <p className="text-center max-w-2xl mb-6">
          Meet your deadlines and take on extra projects.
          <br /> Email us your design brief, and GPLAN will handle <br /> your
          floor plans.
        </p>
      </div>
      <div className="w-full py-2 flex flex-col items-center">
        <div className="flex flex-wrap justify-center items-center space-x-6 text-md">
          <a
            href="tel:+919468839182"
            className="flex items-center space-x-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FiPhone />
            <span>+91 94688 39182</span>
          </a>
          <a
            href="mailto:support@gplan.in"
            className="flex items-center space-x-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FiMail />
            <span>support@gplan.in</span>
          </a>
          <a
            href="https://www.youtube.com/@GPLAN2024/videos"
            className="flex items-center space-x-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube />
            <span>YouTube</span>
          </a>
          <a
            href="https://www.linkedin.com/company/graphplan/"
            className="flex items-center space-x-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BsLinkedin />
            <span>LinkedIn</span>
          </a>
          <a
            href="https://whatsapp.com/channel/0029VajTRLkEQIam6IHBek2W"
            className="flex items-center space-x-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp />
            <span>WhatsApp</span>
          </a>
          <a
            href="https://gplan.in"
            className="flex items-center space-x-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MdLocationOn />
            <span>
              Gplan Services Private Limited, P33 Shriram Colony, Pilani,
              Jhunjhunu - 333031, Rajasthan
            </span>
          </a>
        </div>
        <div className="flex space-x-4 mt-4">
          <a href="/policy" className="hover:underline">
            Privacy Policy
          </a>
          <a href="/terms" className="hover:underline">
            Terms and Conditions
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
